import React from "react";
import { useTranslation } from "react-i18next";
import "./Redirect.scss";
import Card from "../../components/Card";
import classNames from "classnames";
import { ReactComponent as CloseRedIcon } from "../../assets/icons/close_red.svg";

const Redirect = () => {
  const { t: translate } = useTranslation(["redirect"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  return (
    <div className={classNames("login-page", "styled-background")}>
      <Card width="Expired">
        {/* <PensionIcon /> */}
        <div
          className="mb-24"
          style={{ display: "flex", gap: "8px", alignItems: "center" }}
        >
          <CloseRedIcon />
          <h2>{t("page.title", "Sitzung verloren")}</h2>
        </div>

        <p className="mb-12">
          {t(
            "page.paragraph1",
            "Es scheint ein Problem aufgetreten zu sein, und die Versicherungsportal konnte Ihre Kundeninformationen nicht speichern."
          )}
        </p>
        <p className="mb-24">
          {t(
            "page.paragraph2",
            "Bitte kehren Sie zur Pensionsfonds-Seite zurück und klicken Sie auf den Link, um die App erneut aufzurufen."
          )}
        </p>
      </Card>
    </div>
  );
};

export default Redirect;
