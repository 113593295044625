import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components/dist";

import { ReactComponent as Arrow } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as CalcIcon } from "../../../assets/icons/calc-icon.svg";
import { AltersleistungData } from "../../../actions/altersleistung";
import { AustrittData } from "../../../actions/austritt";
import { PensionierungData } from "../../../actions/pensionierung";
import { TodesfallData } from "../../../actions/todesfall";
import { UrlaublData } from "../../../actions/urlaub";
import { VersicherungenData } from "../../../actions/versicherungen";
import { WohneigentumData } from "../../../actions/wohneigentum";
import { ZivilstandData } from "../../../actions/zivilstand";
import { ZusatzsparenData } from "../../../actions/zusatzsparen";
import { handleSidebarButtonClick } from "./utils";
import { ReactElement } from "react";
import { DocumentSummary } from "../../../actions/document";
import { ButtonWrapper } from "./styles";
import CustomRichText from "../../RichText";

interface Props {
  avoidBoxValidation?: boolean;
  boxes: any;
  callback?: (link: string) => void;
  data?:
    | AltersleistungData
    | AustrittData
    | DocumentSummary
    | PensionierungData
    | TodesfallData
    | UrlaublData
    | VersicherungenData
    | WohneigentumData
    | ZivilstandData
    | ZusatzsparenData;
  reactManagedSidebarComponents?: { [key: string]: ReactElement };
}

const REACT_MANAGED_SIDEBAR_COMPONENTS_BOX_KEY = [
  "buyInActionsShortBox",
  "contactDetailsBox",
  "financeExpertBox",
  "fundingRequestBox",
  "mortageRequestBox",
];

export const HygraphSidebarComponents = ({
  avoidBoxValidation,
  boxes,
  callback,
  data,
  reactManagedSidebarComponents,
}: Props) => {
  const history = useHistory();

  const lastBoxIndex = boxes.length - 1;

  return boxes?.map(
    ({ id: componentId, __typename: componentType, box }, index) => {
      if (componentType === "UISidebarBox") {
        if (REACT_MANAGED_SIDEBAR_COMPONENTS_BOX_KEY.includes(box.boxKey)) {
          return (
            <>
              <div key={componentId}>
                {reactManagedSidebarComponents.hasOwnProperty(box.boxKey) &&
                  reactManagedSidebarComponents[box.boxKey]}
              </div>
              {index !== lastBoxIndex && <hr />}
            </>
          );
        }

        return (
          <div key={componentId}>
            <div className="p-48">
              {box.component.map((item) => {
                const link = data?.[box.boxKey]?.link || null;
                const link2 = data?.[box.boxKey]?.link2 || null;

                if (item.__typename === "UITitle" && item.text) {
                  const customStyles = {
                    ...(item.customFontSize && {
                      fontSize: item.customFontSize,
                    }),
                    ...(item.customLineHeight && {
                      lineHeight: item.customLineHeight,
                    }),
                  };

                  return (
                    <h4 key={item.id} className="mb-16" style={customStyles}>
                      {item.text}
                    </h4>
                  );
                }

                if (item.__typename === "UIDescription" && item.text) {
                  return (
                    <p
                      {...(item.marginBottom
                        ? { marginBottom: item.marginBottom }
                        : { className: "mb-24" })}
                      key={item.id}
                    >
                      {item.text}
                    </p>
                  );
                }

                if (item.__typename === "UIContent" && item.content) {
                  return (
                    <CustomRichText
                      key={item.id}
                      content={item?.content.raw.children}
                    />
                  );
                }

                if (item.__typename === "UIButton" && item.label) {
                  // buttonId key is used to identify specific buttons with specific behaviors/styles
                  const isSimulatorCapitalPurchaseButton =
                    item.buttonId === "simulatorCapitalPurchase";

                  const hasMarginTop = isSimulatorCapitalPurchaseButton;

                  const parseLink = () => {
                    if (isSimulatorCapitalPurchaseButton) {
                      return item.link2 || link2;
                    }

                    return item.link || link;
                  };

                  const parsedLink = parseLink();

                  const buttonClickOptions = {
                    boxKey: box.boxKey,
                    callback,
                    history,
                    link: parsedLink,
                  };

                  const getButtonIcon = () => {
                    if (item.icon === "calc") {
                      return <CalcIcon className="ml-auto" />;
                    }

                    return null;
                  };

                  const buttonIcon = getButtonIcon();

                  if (item.variant === "contained") {
                    return (
                      <ButtonWrapper
                        key={item.id}
                        {...(hasMarginTop && { style: { marginTop: 8 } })}
                      >
                        <Button
                          icon={buttonIcon ?? <Arrow className="arrow-white" />}
                          type={ButtonTypes.Standard}
                          color={ButtonColors.Action}
                          style={ButtonStyles.Primary}
                          size={ButtonSizes.Full}
                          onClick={() =>
                            handleSidebarButtonClick(buttonClickOptions)
                          }
                        >
                          {item.label}
                        </Button>
                      </ButtonWrapper>
                    );
                  }

                  if (item.variant === "outlined") {
                    return (
                      <ButtonWrapper key={item.id}>
                        <Button
                          icon={buttonIcon ?? <Arrow />}
                          type={ButtonTypes.Standard}
                          color={ButtonColors.Action}
                          style={ButtonStyles.Secondary}
                          size={ButtonSizes.Full}
                          onClick={() =>
                            handleSidebarButtonClick(buttonClickOptions)
                          }
                        >
                          {item.label}
                        </Button>
                      </ButtonWrapper>
                    );
                  }

                  return (
                    <Button
                      key={item.id}
                      type={ButtonTypes.Text}
                      color={ButtonColors.Action}
                      onClick={() =>
                        handleSidebarButtonClick(buttonClickOptions)
                      }
                    >
                      {item.label}
                      <Arrow className="ml-16" />
                    </Button>
                  );
                }

                return null;
              })}
            </div>
            {index !== lastBoxIndex && <hr />}
          </div>
        );
      }

      return null;
    }
  );
};
