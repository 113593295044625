import { store } from "../store";
import { getSimulatorUrl, logOutLocally, setLoading } from "../actions";
import { i18n } from "../locale";

export const parseDate = (date) => {
  if (!date) return "";
  const left_part = date.split("T")[0];
  const [year, month, day] = left_part.split("-");
  return [day, month, year].join(".");
};

export const calculateValuesInArray = (data: any[]) => {
  let sum = 0;
  for (let i = 0; i <= data.length - 1; i++) {
    sum = sum + data[i].value;
  }
  return sum;
};

export function navigateToSimulator(url) {
  const { dispatch } = store;
  dispatch(setLoading(true));
  dispatch(getSimulatorUrl(url) as any).then((simulatorUrl) => {
    dispatch(setLoading(true));
    const portalUrlWithCallBack = `${simulatorUrl}&portalUrl=${window.location.href}`;

    window.location.href = portalUrlWithCallBack.replace(
      getTenantFromUrl().toLowerCase(),
      getTenantFromUrl().toUpperCase()
    );
  });
}

export function getQueryParameter(name, url?) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regexS = "[\\?&]" + name + "=([^&#]*)";
  let regex = new RegExp(regexS);
  let results = regex.exec(url);
  return results == null ? null : results[1];
}

export const getTenantFromUrl = () => {
  const url = window.location.href;
  const splittedUrl = url.split("/");
  const urlTenant = splittedUrl[3] || "";
  const localStorageTenant = window.localStorage.getItem("tenantName") || "";

  // logout if changed tenant
  if (
    localStorageTenant &&
    urlTenant &&
    urlTenant.toLocaleLowerCase() !== localStorageTenant.toLocaleLowerCase()
  ) {
    logOutLocally();
  }
  
  let tenant = localStorageTenant || urlTenant;

  if (urlTenant) {
    if (urlTenant !== localStorageTenant) {
      tenant = urlTenant;
    }
  }

  return `/${tenant}`;
};

export const getLanguageFromUrl = () => {
  const url = window.location.href;
  const splittedUrl = url.split("/");

  if (
    splittedUrl.length < 6 ||
    (i18n &&
      i18n.languages &&
      i18n.languages.length > 0 &&
      !i18n?.languages?.some((lang) => lang === splittedUrl[4]))
  )
    return "de-CH";

  return splittedUrl[4];
};

//eslint-disable-next-line
export const emailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//eslint-disable-next-line
export const urlRegEx =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

type PatchPayload<T> = {
  [key: string]: T;
};

export const preparePatchPayload = <T>(payload: PatchPayload<T>) => {
  return Object.entries(payload).map(([key, value]) => ({
    value,
    path: `/${key}`,
    op: "replace",
  }));
};
