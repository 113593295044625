import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getTenantFromUrl } from "../utils";
import { checkIfTenantIsValid, globalSettings } from "../actions";

const GlobalSettingsProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTenantData = async () => {
      if (window.location.href.includes("forbidden")) return;

      let tenant = getTenantFromUrl().replace("/", "");
      if (tenant) {
        const isTenantValid = await checkIfTenantIsValid(tenant || "");

        if (isTenantValid) {
          window.localStorage.setItem("tenantName", tenant);
        } else {
          tenant = window.localStorage.getItem("tenantName");
        }
      } else {
        console.error("Tenant inválido ou ausente.");
      }
      dispatch(globalSettings(tenant || "undefined"));
    };

    fetchTenantData();
  }, [dispatch]);

  return children;
};

export default GlobalSettingsProvider;
